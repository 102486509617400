import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/pay/list.php',
    method : 'post',
    data : data
  })
}

//删除
export function deleteUserPay(data) {
  return request({
    url : '/user/pay/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteUserPay(data) {
  return request({
    url : '/user/pay/deleteBatch.php',
    method : 'post',
    data : data
  })
}


//关闭订单
export function handleOrderClose(data) {
  return request({
    url : '/user/wechat/handleOrderClose.php',
    method : 'post',
    data : data
  })
}
